import { StoreDevtoolsModule, provideStoreDevtools } from '@ngrx/store-devtools';

export const environment = {
  production: false,
  BASE_URL: 'https://apix-staging.pupau.ai',
  BOT_PREVIEW_URL: 'https://staging.pupau.ai',
  firebase: {
    apiKey: 'AIzaSyBkNG5XsQ1qZ4sKSyjtgRZdAldguKepA5M',
    authDomain: 'autobot-b3535.firebaseapp.com',
    projectId: 'autobot-b3535',
    storageBucket: 'autobot-b3535.appspot.com',
    messagingSenderId: '939607565050',
    appId: '1:939607565050:web:d39db22b8832394fa09f43',
    measurementId: 'G-X4KNZC7YBB',
  },
  googleAnalytics: {
    enabled: true,
    measurementId: 'G-X4KNZC7YBB',
  },
  TEMPLATE_CONFIG: {
    SSO: {
      google: true,
      apple: true,
      facebook: false,
    },
  },
  appleSSOConfig: {
    clientId: 'ai.pupau.app.service',
    redirectURI: 'https://staging.pupau.ai/__/auth/handler',
    scope: 'name email',
    usePopup: true,
  },
  googleSSOConfig: {
    clientId: '939607565050-j5dkifjpdekg0raar1m8t8qv89ci0u0p.apps.googleusercontent.com',
    redirectURI: 'postmessage',
  },
  level: {
    user: {
      isUser: true,
      level: 100,
    },
    companyAdmin: {
      isUser: false,
      level: 500,
    },
    agencyUser: {
      isUser: true,
      level: 600,
    },
    agencyAdmin: {
      isUser: false,
      level: 700,
    },
  },
  showSettingsConfig: false,
  glueLabsCompanies: <string[]>[],
  providers: [provideStoreDevtools({ maxAge: 25, logOnly: false })],
};
